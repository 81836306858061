<template>
	<div class="single-view tool-color-converter">
		<div class="tool-box">
			<h1>{{ ii('COLOR_CONVERTER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
						v-model="app.query.color"
						ref="tf"
						prepend-icon="paint-brush"
						:placeholder="ii('ENTER_COLOR')"
						@change="refreshQuery"
					></s-text-field>
				</div>

				<div>
					<table class="result-tbl">
						<tr v-if="hex">
							<td>{{ ii('COLOR') }}:</td>
							<td>
								<div class="show-color" :style="'background-color: ' + hex"></div>
							</td>
						</tr>
						<tr v-if="hex">
							<td>HEX:</td>
							<td>{{ hex }}</td>
						</tr>
						<tr v-if="rgb">
							<td>RGB:</td>
							<td>{{ rgb }}</td>
						</tr>
						<tr v-if="hsl">
							<td>HSL:</td>
							<td>{{ hsl }}</td>
						</tr>
						<tr v-if="name">
							<td>{{ ii('NAME') }}:</td>
							<td>{{ name }}</td>
						</tr>
					</table>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

				<div class="examples-box">
					<h3>{{ ii('INPUT_EXAMPLES') }}:</h3>
					<ul>
						<li v-for="example in examples">
							<span class="link" @click="onExampleClick(example)">{{ example }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/vars";

	.tool-color-converter {
		.show-color {
			border: 1px solid #d0d0d0;
			width: 30px;
			height: 15px;
		}
	}
</style>

<script>
	import tinycolor from 'tinycolor2';

	export default {
		data() {
			return {
				color: null,
				html: null,
				examples: [
					'#fff',
					'green',
					'cyan',
					'rgb(120, 130, 140)',
					'rgba(120, 130, 140, 0.5)',
					'hsl(0, 0%, 0%)'
				]
			};
		},
		computed: {
			hex() {
				if (!this.color) return '';
				if (this.color.getAlpha() !== 1) {
					return this.color.toHex8String();
				}

				return this.color.toHexString();
			},
			rgb() {
				if (!this.color) return '';

				return this.color.toRgbString();
			},
			hsl() {
				if (!this.color) return '';

				return this.color.toHslString();
			},
			name() {
				if (!this.color) return '';

				return this.color.toName();
			},
		},
		methods: {
			onExampleClick(example) {
				this.editQueryParams({color: example});

				this.$refs.tf.focus();
			},
			update() {
				if (!this.app.query.color) {
					this.color = null;
					return;
				}

				let raw = this.app.query.color.trim();
				if (!raw) {
					this.color = null;
				} else {
					this.color = tinycolor(raw);
				}
			}
		},
		watch: {
			'app.query.color'() {
				this.update();
			}
		},
		async mounted() {
			this.update();

			this.$refs.tf.focus();
		}
	}
</script>
